@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

[data-theme="light"]{
	--black: #000000;
	--colorTitle: #f9f9f9;
	--cardBorder: #f9f9f9;
	--lightGrey: #999999;;
	--backgroundColor: #f9f9f9;
	--white: #ffffff;
	--darkGrey: #222222;
	--middleGrey: #666666;
	--greenGradient: linear-gradient(30deg, #1cd061 10%, #93F9B9 100%);
	--orangeGradient: linear-gradient(30deg, #fbac74 10%, #ffa81d 100%);
	--redGradient: linear-gradient(30deg, #de7979 10%, #ff4343 100%);
	--lightWhite: rgb(241, 241, 241);
	transition: ease-in-out 0.3s;
}
[data-theme="dark"]{
	--black: #4E58AB;
	--colorTitle: #191919;
	--cardBorder: #4E58AB;
	--lightGrey: #a8b1ff;
	--backgroundColor: #191919;
	--white: #0C1B30;
	--darkGrey: #d3d3d3;
	--middleGrey: #a8b1ff;
	--greenGradient: linear-gradient(30deg, #1cd061 10%, #93F9B9 100%);
	--orangeGradient: linear-gradient(30deg, #fbac74 10%, #ffa81d 100%);
	--redGradient: linear-gradient(30deg, #de7979 10%, #ff4343 100%);
	--lightWhite: #121212;
	transition: ease-in-out 0.3s;
}
*{
	 box-sizing: border-box;
}

*{
	cursor: none;
}
 body{
	font: 14px/20px "Montserrat", sans-serif;
}

::selection{
	background-color: var(--lightGrey);
	color: var(--white);
}

.langSelector{
	margin: 0 auto;
	width: 92%;
	position: absolute;
	height: 6%;
	display: flex;
	justify-content: flex-end;
}

.selectLang{
	line-height: 20px;
}
nav  ul .currentLang::before{
	content: '>';
	font-size: 23px;
	position: absolute;
	color: var(--black);
	font-weight: light;
	left: calc(98% - 2px);
	top: 13px;
	will-change: transform;
	transition: transform 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
  
.selectLang:hover .currentLang::before{
	  transform: rotate(90deg);
}

.otherLang{
	display: none;
}

.selectLang:hover .otherLang{
	display: block;
}

.lang{
	list-style: none;
}

.lang a{
	text-decoration: none;
	color: var(--black);
	line-height: 1;
	font-size: 16px;
	font-weight: light;
}
.pageContainer{
	width: 100%;
	background: var(--backgroundColor);
}

.pageTitle, .myRole{
	display: flex;
	justify-content: center;
	font-size: 40px;
	font-weight: 700;
	letter-spacing: 2px;
	color: var(--colorTitle);
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--black);
	margin: 0;
	padding-top: 30px;
}

.myRole{
	font-size: 25px;
	font-weight: 500;
	-webkit-text-stroke-width: 0px;
	color : var(--black);
}

.madeWithReact{
	display: flex;
	justify-content: center;
	color: var(--middleGrey);
	font-weight: 500;
	font-size: 16px;
}

.reactJS{
	text-decoration: none;
	color: var(--black);
	margin-left: 5px;
}

.gallerie{
	display: flex;
	width: 100%;
	max-width: 1700px;
	margin: auto;
	flex-wrap: wrap;
}

 .cardContainer{
	margin: auto;
	max-width: 400px;
	width: 30%;
	min-width: 350px;
	margin-bottom: 50px;
	border: 2px solid var(--cardBorder);
	border-radius: 20px;
}

.pageTheme{
	width: 60px;
	height: 60px;
	padding: 10px;
	background: var(--lightWhite);
	border-radius: 50%;
	box-shadow: 0px 6px 20px -16px var(--black);
	position: absolute;
	top: 25px;
	left: 30px;
	transition: transform 0.5s;
}

.pageTheme:hover{
	transform: scale(1.1);
	transition: transform 0.5 s;
}



.invisible{
	opacity:0;
}

@media (max-width: 1200px){
	.cardDescription{
		font-size: 14px;
	}
	.invisible{
		font-size: 11px;
	}
}
@media (max-width: 450px){
	* {
		cursor: auto;
		cursor: pointer;
	}
	
	.pageTheme{
		width: 30px;
		height: 30px;
		left: 9px;
		padding: 4px;
		top: 28px;
	}
	.pageTitle{
		font-size: 25px;
	}

	.myRole{
		font-size: 15px;
		padding-top: 15px;
	}
	.cardContainer{
		margin-bottom: 50px;
	}
}
.card{
	background: var(--white);
	width: 100%;
	border-radius: 19px;
	position: relative;
	text-align: center;
	z-index: 1;
	height: 500px;	 
	box-shadow: 0px 6px 20px -16px var(--black);
}

.projectImg{
	margin-top: 3px;
	margin-bottom: 7px;
	width: 96%;
	border-radius: 10px;
	transition: transform 0.5s;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	object-fit: cover;
	height: 50%;
}

.videoPlayer{
	margin-top: 3px;
	margin-bottom: 7px;
	width: 96%;
	border-radius: 10px;
	transition: transform 0.5s;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	height: auto;
}

.videoPlayer:hover{
	transform: scale(1.02);
	transition: transform 0.5s;
}

.projectTitle{
	text-decoration: none;
	color: var(--darkGrey);
}

.projectImg:hover{
	transform: scale(1.02);
	transition: transform 0.5s;
}

.cardDate{
	font-size: 12px;
	color: var(--middleGrey);
	font-weight: 500;
	margin-bottom: 3px;
}

.responsivePhone{
	width: 30px;
	height: 30px;
	padding: 3px;
	position: absolute;
	left: 3px;
	border-radius: 5px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

#greenColor{
	background: var(--greenGradient);
}

#redColor{
	background: var(--redGradient);
}

#orangeColor{
	background: var(--orangeGradient);
}

.responsiveComputer{
	width: 30px;
	height: 30px;
	padding: 4px;
	position: absolute;
	left: 37px;
	border-radius: 5px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

 .cardTitle{
	 font-size: 20px;
	 color: var(--darkGrey);
	 font-weight: 600;
	 margin-bottom: 5px;
}

 .cardDescription{
	 padding: 10px;
	 margin-bottom: 15px;
	 text-align: left;
	 font-size: 14px;
	 color: var(--darkGrey);
}

 .cardBottom {
	border-top: 1px solid var(--darkGrey);
	font-weight: 300;
	border-bottom-left-radius: 14px;
	border-bottom-right-radius: 14px;
}

.cardBottom .oneThird {
	 width: 33%;
	 float: left;
	 margin-top: 5px;
}

.cardBottom .twoThird{
  width: 66%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid var(--darkGrey);
}

 .madeFor, .cardGrade{
	 font-size: 24px;
	 margin-bottom: 10px;
	 color: var(--middleGrey);
}

.madeFor{
	margin-top: 5px;
	font-size: 18px;
	font-weight: 400;
}

.name {
	font-size: 16px;
	text-decoration: none;;
	color: var(--black);
	margin-left: 5px;
	font-weight: 500;
}

.cardGrade{
	font-size: 18px;
	font-weight: 400;
}

.grade{
	color: var(--black);
	font-weight: 500;
}

.madeWith{
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 16px;
	margin: 4px 0;
	color: var(--darkGrey);
}

.participant{
	margin: 0 10px;
	text-decoration: none;
	color: var(--darkGrey);
	font-weight: 600;
}

.containerDetail{
	border-bottom: 1px solid var(--darkGrey);
	background: var(--lightWhite);
}

.containerBottom{
	background: var(--white);
	border-bottom-left-radius: 14px;
	border-bottom-right-radius: 14px;
	
}

@media (max-width: 750px){
    .videoPlayer{
		margin-top: 15px;
		margin-bottom: 15px;
    }
}
.footer{
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 100%;
	padding-bottom: 30px;;
	flex-direction: column;
}

.cv{
	text-decoration: none;
	margin-left: 5px;
	color: var(--black);
	font-weight: 500;
}

.download{
	color: var(--middleGrey);
	display: flex;
	justify-content: center;
}
.cursor{
    z-index: 200;
    pointer-events: none;
    position: fixed;
    display: block;
    width: 25px;
    height: 25px;
    background-color: white;
    mix-blend-mode: difference;
    border-radius: 50%;
    transition: all 0.1s linear;
}

.cursor_interior{
    z-index: 200;
    /* display: block; */
    position: fixed;
    width: 5px;
    height: 5px;
    background-color: white;
    mix-blend-mode: difference;
    pointer-events: none;
    border-radius: 50%;
}

/* .cursor_interior{
    z-index: 200;
    display: block;
    position: fixed;
    width: 5px;
    height: 5px;
    background-color: white;
    mix-blend-mode: difference;
    pointer-events: none;
    border-radius: 50%;
    top: -30px;
    left: -30px;
} */

@media (max-width: 450px){
    .cursor{
        display: none;
    }
    
    .cursor_interior{
        display: none;
    }
}
