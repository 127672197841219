.cursor{
    z-index: 200;
    pointer-events: none;
    position: fixed;
    display: block;
    width: 25px;
    height: 25px;
    background-color: white;
    mix-blend-mode: difference;
    border-radius: 50%;
    transition: all 0.1s linear;
}

.cursor_interior{
    z-index: 200;
    /* display: block; */
    position: fixed;
    width: 5px;
    height: 5px;
    background-color: white;
    mix-blend-mode: difference;
    pointer-events: none;
    border-radius: 50%;
}

/* .cursor_interior{
    z-index: 200;
    display: block;
    position: fixed;
    width: 5px;
    height: 5px;
    background-color: white;
    mix-blend-mode: difference;
    pointer-events: none;
    border-radius: 50%;
    top: -30px;
    left: -30px;
} */

@media (max-width: 450px){
    .cursor{
        display: none;
    }
    
    .cursor_interior{
        display: none;
    }
}