.card{
	background: var(--white);
	width: 100%;
	border-radius: 19px;
	position: relative;
	text-align: center;
	z-index: 1;
	height: 500px;	 
	box-shadow: 0px 6px 20px -16px var(--black);
}

.projectImg{
	margin-top: 3px;
	margin-bottom: 7px;
	width: 96%;
	border-radius: 10px;
	transition: transform 0.5s;
	user-select: none;
	object-fit: cover;
	height: 50%;
}

.videoPlayer{
	margin-top: 3px;
	margin-bottom: 7px;
	width: 96%;
	border-radius: 10px;
	transition: transform 0.5s;
	user-select: none;
	height: auto;
}

.videoPlayer:hover{
	transform: scale(1.02);
	transition: transform 0.5s;
}

.projectTitle{
	text-decoration: none;
	color: var(--darkGrey);
}

.projectImg:hover{
	transform: scale(1.02);
	transition: transform 0.5s;
}

.cardDate{
	font-size: 12px;
	color: var(--middleGrey);
	font-weight: 500;
	margin-bottom: 3px;
}

.responsivePhone{
	width: 30px;
	height: 30px;
	padding: 3px;
	position: absolute;
	left: 3px;
	border-radius: 5px;
	user-select: none;
}

#greenColor{
	background: var(--greenGradient);
}

#redColor{
	background: var(--redGradient);
}

#orangeColor{
	background: var(--orangeGradient);
}

.responsiveComputer{
	width: 30px;
	height: 30px;
	padding: 4px;
	position: absolute;
	left: 37px;
	border-radius: 5px;
	user-select: none;
}

 .cardTitle{
	 font-size: 20px;
	 color: var(--darkGrey);
	 font-weight: 600;
	 margin-bottom: 5px;
}

 .cardDescription{
	 padding: 10px;
	 margin-bottom: 15px;
	 text-align: left;
	 font-size: 14px;
	 color: var(--darkGrey);
}

 .cardBottom {
	border-top: 1px solid var(--darkGrey);
	font-weight: 300;
	border-bottom-left-radius: 14px;
	border-bottom-right-radius: 14px;
}

.cardBottom .oneThird {
	 width: 33%;
	 float: left;
	 margin-top: 5px;
}

.cardBottom .twoThird{
  width: 66%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid var(--darkGrey);
}

 .madeFor, .cardGrade{
	 font-size: 24px;
	 margin-bottom: 10px;
	 color: var(--middleGrey);
}

.madeFor{
	margin-top: 5px;
	font-size: 18px;
	font-weight: 400;
}

.name {
	font-size: 16px;
	text-decoration: none;;
	color: var(--black);
	margin-left: 5px;
	font-weight: 500;
}

.cardGrade{
	font-size: 18px;
	font-weight: 400;
}

.grade{
	color: var(--black);
	font-weight: 500;
}

.madeWith{
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 16px;
	margin: 4px 0;
	color: var(--darkGrey);
}

.participant{
	margin: 0 10px;
	text-decoration: none;
	color: var(--darkGrey);
	font-weight: 600;
}

.containerDetail{
	border-bottom: 1px solid var(--darkGrey);
	background: var(--lightWhite);
}

.containerBottom{
	background: var(--white);
	border-bottom-left-radius: 14px;
	border-bottom-right-radius: 14px;
	
}

@media (max-width: 750px){
    .videoPlayer{
		margin-top: 15px;
		margin-bottom: 15px;
    }
}